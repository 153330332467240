// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-shareasmile-js": () => import("./../../../src/pages/shareasmile.js" /* webpackChunkName: "component---src-pages-shareasmile-js" */),
  "component---src-pages-supplies-js": () => import("./../../../src/pages/supplies.js" /* webpackChunkName: "component---src-pages-supplies-js" */),
  "component---src-pages-supplies-terms-conditions-js": () => import("./../../../src/pages/supplies-terms-conditions.js" /* webpackChunkName: "component---src-pages-supplies-terms-conditions-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-wisdom-application-js": () => import("./../../../src/pages/wisdom-application.js" /* webpackChunkName: "component---src-pages-wisdom-application-js" */),
  "component---src-pages-wisdom-js": () => import("./../../../src/pages/wisdom.js" /* webpackChunkName: "component---src-pages-wisdom-js" */),
  "component---src-pages-wisdom-step-1-js": () => import("./../../../src/pages/wisdom-step-1.js" /* webpackChunkName: "component---src-pages-wisdom-step-1-js" */),
  "component---src-pages-wisdom-step-2-js": () => import("./../../../src/pages/wisdom-step-2.js" /* webpackChunkName: "component---src-pages-wisdom-step-2-js" */),
  "component---src-pages-wisdom-terms-conditions-js": () => import("./../../../src/pages/wisdom-terms-conditions.js" /* webpackChunkName: "component---src-pages-wisdom-terms-conditions-js" */),
  "component---src-templates-academicadvantage-js": () => import("./../../../src/templates/academicadvantage.js" /* webpackChunkName: "component---src-templates-academicadvantage-js" */),
  "component---src-templates-anesthesia-options-js": () => import("./../../../src/templates/anesthesia-options.js" /* webpackChunkName: "component---src-templates-anesthesia-options-js" */),
  "component---src-templates-community-js": () => import("./../../../src/templates/community.js" /* webpackChunkName: "component---src-templates-community-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-covid-19-js": () => import("./../../../src/templates/covid-19.js" /* webpackChunkName: "component---src-templates-covid-19-js" */),
  "component---src-templates-dental-implants-faq-js": () => import("./../../../src/templates/dental-implants-faq.js" /* webpackChunkName: "component---src-templates-dental-implants-faq-js" */),
  "component---src-templates-doctor-one-js": () => import("./../../../src/templates/doctor-one.js" /* webpackChunkName: "component---src-templates-doctor-one-js" */),
  "component---src-templates-get-started-js": () => import("./../../../src/templates/get-started.js" /* webpackChunkName: "component---src-templates-get-started-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-our-oral-surgery-practice-js": () => import("./../../../src/templates/our-oral-surgery-practice.js" /* webpackChunkName: "component---src-templates-our-oral-surgery-practice-js" */),
  "component---src-templates-patient-instructions-js": () => import("./../../../src/templates/patient-instructions.js" /* webpackChunkName: "component---src-templates-patient-instructions-js" */),
  "component---src-templates-patient-stories-js": () => import("./../../../src/templates/patient-stories.js" /* webpackChunkName: "component---src-templates-patient-stories-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-procedures-js": () => import("./../../../src/templates/procedures.js" /* webpackChunkName: "component---src-templates-procedures-js" */),
  "component---src-templates-sands-js": () => import("./../../../src/templates/sands.js" /* webpackChunkName: "component---src-templates-sands-js" */),
  "component---src-templates-single-community-js": () => import("./../../../src/templates/singleCommunity.js" /* webpackChunkName: "component---src-templates-single-community-js" */),
  "component---src-templates-single-instruction-js": () => import("./../../../src/templates/singleInstruction.js" /* webpackChunkName: "component---src-templates-single-instruction-js" */),
  "component---src-templates-single-page-without-videos-js": () => import("./../../../src/templates/singlePageWithoutVideos.js" /* webpackChunkName: "component---src-templates-single-page-without-videos-js" */),
  "component---src-templates-single-procedure-with-multiple-videos-js": () => import("./../../../src/templates/singleProcedureWithMultipleVideos.js" /* webpackChunkName: "component---src-templates-single-procedure-with-multiple-videos-js" */),
  "component---src-templates-single-procedure-with-one-video-js": () => import("./../../../src/templates/singleProcedureWithOneVideo.js" /* webpackChunkName: "component---src-templates-single-procedure-with-one-video-js" */),
  "component---src-templates-single-procedure-without-videos-js": () => import("./../../../src/templates/singleProcedureWithoutVideos.js" /* webpackChunkName: "component---src-templates-single-procedure-without-videos-js" */),
  "component---src-templates-single-review-js": () => import("./../../../src/templates/singleReview.js" /* webpackChunkName: "component---src-templates-single-review-js" */),
  "component---src-templates-stopthevape-js": () => import("./../../../src/templates/stopthevape.js" /* webpackChunkName: "component---src-templates-stopthevape-js" */),
  "component---src-templates-three-d-imaging-js": () => import("./../../../src/templates/three-d-imaging.js" /* webpackChunkName: "component---src-templates-three-d-imaging-js" */),
  "component---src-templates-treatment-options-js": () => import("./../../../src/templates/treatment-options.js" /* webpackChunkName: "component---src-templates-treatment-options-js" */),
  "component---src-templates-why-choose-js": () => import("./../../../src/templates/why-choose.js" /* webpackChunkName: "component---src-templates-why-choose-js" */),
  "component---src-templates-wisdom-teeth-removal-faq-js": () => import("./../../../src/templates/wisdom-teeth-removal-faq.js" /* webpackChunkName: "component---src-templates-wisdom-teeth-removal-faq-js" */),
  "component---src-templates-your-first-visit-js": () => import("./../../../src/templates/your-first-visit.js" /* webpackChunkName: "component---src-templates-your-first-visit-js" */)
}

